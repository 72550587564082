import { React, FontAwesomeIcon, fa } from "../../utils/imports";

const Footer = () => {
	return (
		<section id="footer" className="footer">
			<div className="container">
				
				<div className="copyright">
					© Copyright{" "}
					<strong>
						<span>Mystic Melon Atelier</span>
					</strong>
					. All Rights Reserved
				</div>
			</div>
			<a href="#" className="text-light back-to-top d-flex align-items-center justify-content-center">
				<FontAwesomeIcon icon={fa.fal["arrow-up"]} />
			</a>
		</section>
	);
};

export default Footer;
