import React, { useEffect } from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const Navigation = ({ headerRef }) => {
	useEffect(() => {
		if (typeof window !== "undefined" && typeof document !== "undefined") {
			const handleScroll = () => {
				if (headerRef.current) {
					headerRef.current.classList.toggle("header-scrolled", window.scrollY > 5);
				}
			};

			window.addEventListener("scroll", handleScroll);

			return () => {
				window.removeEventListener("scroll", handleScroll);
			};
		}
	}, [headerRef]);

	return (
		<div ref={headerRef} id="header" className="header">
			<Navbar expand="lg" id="main-navbar" className="navbar-light">
				<Container>
					<Navbar.Brand as={Link} to="/">
						Mystic Melon Atelier
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
						<Nav>
							<ul className="navbar-nav ml-auto">
								<li className="nav-item">
									<Link className="nav-link" to="/">
										Home
									</Link>
								</li>
								<li className="nav-item">
									<Nav.Link href="#about-section" className="scrollto">
										About
									</Nav.Link>
								</li>
								<li className="nav-item">
									<Nav.Link href="#a-section" className="scrollto">
										Web Development
									</Nav.Link>
								</li>
								<li className="nav-item">
									<Nav.Link href="#b-section" className="scrollto">
										Illustration
									</Nav.Link>
								</li>
								<li className="nav-item">
									<Nav.Link href="#contact-section" className="scrollto">
										Contact
									</Nav.Link>
								</li>
							</ul>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</div>
	);
};

export default Navigation;
